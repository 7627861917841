<script>
import {defineAsyncComponent} from "vue";
export default {
  name: 'App',
  components: {
    "qr-code":defineAsyncComponent(() => import("@/components/qr.vue")),
    "vcard":defineAsyncComponent(() => import("@/components/vcard.vue"))
  }
}
</script>

<style lang="scss">
@use "./scss/main";
</style>
